html, body, #root, main {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	background-color: black;
	font-family: Arial, sans-serif;
}
/* IE11 has no default style for main tag */
main {
	display: block;
}

em {
	font-weight: bold;
	font-style: normal;
}

#splash {
	font-size: 2em;
	padding: 2em;
	box-sizing: border-box;
	margin-right: 20rem;
	color: #ddd;
}

#errors {
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 1;
	background-color: rgb(190, 128, 128);
	color: rgb(59, 0, 0);
	padding: 1rem;
	box-sizing: border-box;
}
#errors li {
	font-family: Consolas, monospace;
}
#errors h2 {
	margin-top: 0;
}
#errors code {
	font-size: larger;
	font-weight: bold;
}
#errors button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}

svg.iomarker {
	z-index: 1;
	position: absolute;
}

video.thumbnail {
	position: absolute;
	bottom: 36px;
	z-index: 1;
}